<script setup>

</script>

<template>
  <div>
    IREC：一个简洁的推荐网站，提建议请发邮件到312036029@qq.com。
  </div>
</template>

<style scoped>

</style>
<script>
import {ref} from 'vue';
import axios from 'axios';
import Vditor from 'vditor';
import 'vditor/dist/index.css';

const vditor = ref(null);

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData(id) {
      axios.get('https://www.ymshici.com/api/tech/v1.0/markdown?id=' + id)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            vditor.value.setValue(response.data.markdown);
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    vditor.value = new Vditor('vditor', {
      mode: 'ir',
      toolbarConfig: {
        pin: true,
      },
      preview: {
        markdown: {
          toc: true
        },
      },
      cache: {
        enable: false,
      },
      outline: {
        enable: true // 开启大纲功能
      },
      after: () => {
        this.fetchData(this.$route.params.id);
        //
      },
    });
    // 在组件挂载后获取数据
  }
};


</script>

<template>
  <h1>{{ data.title }}<a :href='`https://ymshici.com/tech/${data.id}.html`'>#</a></h1>
  <div id="vditor"></div>
</template>

<style>

h1 {
  text-align: center;
}
.vditor-outline {
  display: block;
  position: fixed;
  z-index: 1;
  border: 1px dashed lightgray;
}
</style>

<template>
  <footer class="footer footer-hid">
    <p>
      Copyright © 2024 | 
      <a class="footer-color" href="https://beian.miit.gov.cn/" target="_blank">京ICP备16025998号-3</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.footer {
  width: 100%;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: 1px solid #e5e5e5;
  height: 50px;
  //position: absolute;
  //bottom: 0;
  //z-index: -1;
}

.footer-color {
    color: #999;
}

</style>

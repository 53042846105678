<template>
  <nav class="navbar" :class="{ hidden: isHidden }">
    <ul class="nav-links">
      <li>
        <router-link to="/">主页</router-link>
      </li>
      <li>
        <router-link to="/doc">订阅</router-link>
      </li>
      <li>
        <router-link to="/saying">格言</router-link>
      </li>
      <li>
        <router-link to="/poem">诗歌</router-link>
      </li>
      <li>
        <router-link to="/efficiency">效率</router-link>
      </li>
      <li>
        <router-link to="/tech">技术</router-link>
      </li>
      <li>
        <router-link to="/about">关于</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {ref, onMounted, onUnmounted} from 'vue';

export default {
  name: 'AppNavbar',
  setup() {
    const isHidden = ref(false);

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const threshold = 150; // 设置触发隐藏的滚动阈值
      isHidden.value = scrollTop > threshold;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isHidden,
    };
  },
};
</script>

<style scoped>
.navbar {
  background-color: #333;
  transition: transform 0.3s ease-in-out;
}

.navbar.hidden {
  transform: translateY(-100%); /* 隐藏导航栏 */
  height: 0;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  padding: 5px 10px;
  display: inline;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  display: block;
}

.nav-links li a:hover {
  background-color: #ddd;
  color: black;
}

.router-link-active {
  color: red;
}
</style>

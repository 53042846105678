<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData() {
      axios.get('https://www.ymshici.com/api/doc/v1.0/docs')
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData();  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <ul class="saying-ul">
          <li v-for="(doc, index) in data.docs" :key="index">
            <h2><a target="_blank" :href="`${doc.url}`">{{ doc.title }}</a></h2>
            <span v-for="(word, index2) in doc.words" :key="index2">
              <router-link :to="`/doc/${word}`" target="_blank">{{ word }}</router-link>
            </span>
            <div>{{ doc.content }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '@/css/main.css';

span {
  padding-right: 5px;
}
</style>
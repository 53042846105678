<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  // 组件内的守卫可以在参数改变时重新获取数据
  beforeRouteUpdate(to, from, next) {
    // 参数改变时，例如从 /user/1 到 /user/2
    console.log('参数更新了:', to.params.word);
    this.fetchData(to.params.word);
    next();
  },
  methods: {
    fetchData(word) {
      axios.get('https://www.ymshici.com/api/english/v1.0/trans?w=' + word)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            document.title = word + "的含义_乐享推荐";
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  created() {
    // 组件创建完成后获取数据
    this.fetchData(this.$route.params.word);
  }
};
</script>

<template>
  <div>单词：{{ data.w }}</div>
  <div>翻译：{{ data.chinese }}</div>
  <div>
    <router-link :to="`/sentence`">句子</router-link>
  </div>
  <div>
    <router-link :to="`/word`">单词</router-link>
  </div>
  <ul class="saying-ul">
    <li v-for="(sentence, index) in data.sentences" :key="index">
      <div>{{ sentence.english }}</div>
      <span v-for="(word, index2) in sentence.words" :key="index2">
              <router-link :to="`/word/${word}`" target="_blank">{{ word }}</router-link>
            </span>
      <div>{{ sentence.chinese }}</div>
    </li>
  </ul>
</template>

<style scoped>
@import '@/css/main.css';


</style>
<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
      query: ref('重阳')
    };
  },
  methods: {
    fetchData(query) {
      axios.get('https://www.ymshici.com/api/poem/query?query=' + query)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            document.title = query + "_乐享推荐";
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData('重阳');  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <h1>{{ query }}相关的诗词</h1>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <ul class="saying-ul">
          <li v-for="(doc, index) in data.docs" :key="index">
            <h2><a target="_blank" :href="`https://www.ymshici.com/author/${doc.author_id}.html`">{{ doc.author }}</a>《
              <router-link :to="`/poem/${doc.id}`" target="_blank">{{ doc.title }}</router-link>
              》
            </h2>
            <div>{{ doc.content }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
<template>
  <div class="login">
    <h2>登录</h2>
    <el-form ref="loginFormRef" :model="loginForm" :rules="rules" class="login-form">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {reactive, ref} from 'vue';
// import {ElForm, ElFormItem, ElInput, ElButton} from 'element-plus';

export default {
  name: 'AppLogin',
  setup() {
    const loginForm = reactive({
      username: '',
      password: '',
    });

    const rules = {
      username: [
        {required: true, message: '请输入用户名', trigger: 'blur'},
      ],
      password: [
        {required: true, message: '请输入密码', trigger: 'blur'},
      ],
    };

    const loginFormRef = ref(null);

    const handleSubmit = () => {
      // loginFormRef.value.validate((valid) => {
      //   if (valid) {
      //     // 调用登录API
      //     console.log('登录', loginForm);
      //     // 这里可以添加你的登录逻辑，例如调用API
      //   } else {
      //     console.log('表单验证失败');
      //   }
      // });
    };

    return {
      loginFormRef,
      loginForm,
      handleSubmit,
      rules
    };
  },
  // components: {
  //   ElForm,
  //   ElFormItem,
  //   ElInput,
  //   ElButton,
  // }
}


</script>

<style scoped>
.login-form {
  width: 300px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
<template>
  <div id="app" class="app-container">
    <AppNavbar/>
    <main>
      <router-view/>
    </main>
    <AppFooter/>
  </div>
</template>

<script>
import AppNavbar from "@/views/main/AppNavbar.vue";
import AppFooter from "@/views/main/AppFooter.vue";

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter
  }
}
</script>

<style>

@import '@/css/main.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 全局样式 */
body, html, .app-container {
  margin: 0;
  padding: 0;
  height: 100%; /* 确保容器高度为100% */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 至少占满整个视口 */
}

main {
  flex: 1; /* 让主要内容区占据剩余的空间 */
}

footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
}
</style>

// router/index.js
import {createRouter, createWebHistory} from 'vue-router';
import AppHome from '../views/main/AppHome.vue';
import AppAbout from '../views/main/AppAbout.vue';
import AppLogin from '../views/main/AppLogin.vue';


import AppSaying from '../views/classical/AppSaying.vue';
import AppPoem from '../views/classical/AppPoem.vue';
import AppPoemQuery from '../views/classical/AppPoemQuery.vue';
import AppPoemHtml from '../views/classical/AppPoemHtml.vue';
// import AppPoemGen from '../views/classical/AppPoemGen.vue';


import AppEnglish from '../views/english/AppEnglish.vue';
import AppSentence from '../views/english/AppSentence.vue';
import AppWordSearch from '../views/english/AppWordSearch.vue';
import AppWord from '../views/english/AppWord.vue';

import AppDoc from '../views/doc/AppDoc.vue';
import AppDocSearch from '../views/doc/AppDocSearch.vue';
import AppEfficient from '../views/doc/AppEfficient.vue';

import AppTech from '../views/tech/AppTech.vue';
import AppTechTag from '../views/tech/AppTechTag.vue';
import AppTechQuery from '../views/tech/AppTechQuery.vue';
import AppTechEdit from '../views/tech/AppTechEdit.vue';
import AppTechHtml from '../views/tech/AppTechHtml.vue';

const web_title = "乐享推荐"
// 定义路由
const routes = [
    {
        path: '/',
        name: 'Home',
        component: AppHome,
        meta: {title: web_title}
    },
    {
        path: '/tech',
        name: 'Tech',
        component: AppTech,
        meta: {title: '技术_' + web_title}
    },
    {
        path: '/tech/tag/:name',
        name: 'TechTag',
        component: AppTechTag,
        meta: {title: '技术_' + web_title}
    },
    {
        path: '/tech/query/:name',
        name: 'TechQuery',
        component: AppTechQuery,
        meta: {title: '技术_' + web_title}
    },
    {
        path: '/tech/edit/:id',
        name: 'TechEdit',
        component: AppTechEdit,
        meta: {title: '编辑_' + web_title}
    },

    {
        path: '/tech/:id',
        name: 'TechHtml',
        component: AppTechHtml,
        meta: {title: '技术博客_' + web_title}
    },
    {
        path: '/doc',
        name: 'Doc',
        component: AppDoc,
        meta: {title: '订阅_' + web_title}
    },
    {
        path: '/doc/:query',
        name: 'DocSearch',
        component: AppDocSearch,
        meta: {title: '技术博客_' + web_title}
    },

    {
        path: '/poem',
        name: 'AppPoem',
        component: AppPoem,
        meta: {title: '诗歌_' + web_title}
    },
    {
        path: '/poem/:id',
        name: 'AppPoemHtml',
        component: AppPoemHtml,
        meta: {title: '诗歌_' + web_title}
    },
    {
        path: '/poem/query/:query',
        name: 'AppPoemQuery',
        component: AppPoemQuery,
        meta: {title: '诗歌_' + web_title}
    },


    {
        path: '/english',
        name: 'English',
        component: AppEnglish,
        meta: {title: '翻译_' + web_title}
    },
    {
        path: '/sentence',
        name: 'Sentence',
        component: AppSentence,
        meta: {title: '英文_' + web_title}
    },
    {
        path: '/word',
        name: 'Word',
        component: AppWord,
        meta: {title: '单词列表_' + web_title}
    },
    {
        path: '/word/:word',
        name: 'WordSearch',
        component: AppWordSearch,
        meta: {title: '单词_' + web_title}
    },
    {
        path: '/saying',
        name: 'AppSaying',
        component: AppSaying,
        meta: {title: '格言_' + web_title}
    },
    {
        path: '/efficiency',
        name: 'AppEfficient',
        component: AppEfficient,
        meta: {title: '效率_' + web_title}
    },
    {
        path: '/about',
        name: 'About',
        component: AppAbout,
        meta: {title: '关于_' + web_title}
    },
    {
        path: '/login',
        name: 'Login',
        component: AppLogin,
        meta: {title: '登录_' + web_title}
    },
];

// 创建路由器实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
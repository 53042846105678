<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData() {
      axios.get('https://www.ymshici.com/api/tech/v1.0/docs')
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData();  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <ul class="saying-ul">
    <li v-for="(doc, index) in data.docs" :key="index">
      <h2>
        <router-link :to="`/tech/${doc.id}`" target="_blank">{{ doc.title }}</router-link>
      </h2>
      <span class="r5px" v-for="(name, index2) in doc.tags" :key="index2">
        <router-link :to="`/tech/tag/${name}`" target="_blank">{{ name }}</router-link>
      </span> &nbsp;
      <div>{{ doc.content }}</div>
    </li>
  </ul>
</template>

<style scoped>
@import '@/css/main.css';
</style>
<script setup>
import {ref} from 'vue';
import axios from "axios";

const text = ref(''); // 使用ref创建响应式数据
const english = ref(''); // 使用ref创建响应式数据
const chinese = ref(''); // 使用ref创建响应式数据
const words = ref([]); // 使用ref创建响应式数据

const handleSubmit = () => {
  // 提交逻辑
  // 这里可以添加发送请求的代码
  axios.get('https://www.ymshici.com/api/english/v1.0/add?s=' + text.value)
      .then(response => {
        english.value = response.data.english;
        chinese.value = response.data.chinese;
        words.value = response.data.words;
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
};

</script>

<template>
  <textarea v-model="text"></textarea>
  <button @click="handleSubmit">提交</button>
  <div>英文：{{ english }}</div>
  <div>
          <span v-for="(word, index2) in words" :key="index2">
              <router-link :to="`/word/${word}`" target="_blank">{{ word }}</router-link>
            </span>
  </div>
  <div>中文：{{ chinese }}</div>


  <div>
    <router-link :to="`/word`">单词</router-link>
  </div>
  <div>
    <router-link :to="`/sentence`">句子</router-link>
  </div>
</template>

<style scoped>
textarea {
  width: 800px;
  height: 300px;
}


button {
  width: 80px;
}
</style>
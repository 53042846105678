<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData() {
      axios.get('https://www.ymshici.com/api/english/v1.0/words')
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData();  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <div>
    <router-link :to="`/sentence`">句子</router-link>
  </div>
  <div>
    <router-link :to="`/word`">单词</router-link>
  </div>
  <ul class="saying-ul">
    <li v-for="(word, index) in data.words" :key="index">
      <div>
        <router-link :to="`/word/${word.english}`" target="_blank">{{ word.english }}</router-link>
      </div>
      <div>{{ word.chinese }}</div>
    </li>
  </ul>
</template>

<style scoped>
@import '@/css/main.css';

span {
  padding-right: 5px;
}
</style>
<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData() {
      axios.get('https://www.ymshici.com/api/saying/v1.0/sayings')
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData();  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <!--    <h1>名言警句</h1>-->
  <ul class="saying-ul">
    <li v-for="(saying, index) in data.sayings" :key="index">
      {{ saying }}
    </li>
  </ul>
</template>

<style scoped>
@import '@/css/main.css';
</style>
<script>
import axios from 'axios';
import { ref } from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  methods: {
    fetchData() {
      axios.get('https://www.ymshici.com/api/poem/v1.0/docs')
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  mounted() {
    this.fetchData();  // 在组件挂载后获取数据
  }
};
</script>

<template>
  <div>
    <ul class="saying-ul">
      <li v-for="(doc, index) in data.docs" :key="index">
        <h2><a target="_blank" :href="`https://www.ymshici.com/author/${doc.author_id}.html`">{{doc.author}}</a>《<router-link :to="`/poem/${doc.id}`" target="_blank">{{ doc.title }}</router-link>》</h2>
        <div>{{ doc.content }}</div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
@import '@/css/main.css';
</style>
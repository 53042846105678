<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  data() {
    return {
      data: ref([]),  // 初始化数据
    };
  },
  // 组件内的守卫可以在参数改变时重新获取数据
  beforeRouteUpdate(to, from, next) {
    // 参数改变时，例如从 /user/1 到 /user/2
    console.log('参数更新了:', to.params.query);
    this.fetchData(to.params.query);
    next();
  },
  methods: {
    fetchData(query) {
      axios.get('https://www.ymshici.com/api/doc/v1.0/search?q=' + query)
          .then(response => {
            this.data = response.data;  // 将获取的数据存储在 data 属性中
            document.title = query + "相关的内容_乐享推荐";
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    }
  },
  created() {
    // 组件创建完成后获取数据
    this.fetchData(this.$route.params.query);
  }
};
</script>

<template>
  <ul class="saying-ul">
    <li v-for="(doc, index) in data.docs" :key="index">
      <h2><a target="_blank" :href="`${doc.url}`">{{ doc.title }}</a></h2>
      <span v-for="(word, index2) in doc.words" :key="index2">
              <router-link :to="`/doc/${word}`" target="_blank">{{ word }}</router-link>
            </span> &nbsp;
      <div>{{ doc.content }}</div>
    </li>
  </ul>
</template>

<style scoped>
@import '@/css/main.css';

span {
  padding-right: 5px;
}
</style>